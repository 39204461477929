import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    ListProps,
    DateField,
    useRedirect,
    Pagination
} from 'react-admin'
import MaterialTextfield from '@material-ui/core/TextField'
import { Record, useNotify, useTranslate } from 'ra-core'
import { makeStyles } from '@material-ui/core/styles'
import FileCopyIcon from '@mui/icons-material/FileCopy'

import datagridStyles from 'layout/styles/datagridStyles'
import PackageListFilters from './PackageListFilters'
import DatagridPublishedColumnContent from 'components/common/DatagridPublishedColumnContent'
import { useDuplicatePackageMutation } from 'apollo/package_manager/mutations/DuplicatePackage.generated'
import BulkActionButtons from 'components/common/BulkActionButtons'
import { CustomDatagridBody } from 'components/common/CustomDatagridBody'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography
} from '@material-ui/core'
import { DEFAULT_PER_PAGE, ROWS_PER_PAGE } from '../../globals'
import { ChangeEvent, MouseEvent, useState } from 'react'

const styles = makeStyles({
    fileIcon: {
        '&:hover': {
            transform: `scale(1.1)`
        }
    },
    dialogActions: {
        padding: 0,
        paddingBottom: '1rem'
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px'
    }
})

const PackageList = (props: ListProps) => {
    const classes = styles()
    const datagridClasses = datagridStyles()
    const translate = useTranslate()
    const redirect = useRedirect()
    const [duplicatePackageMutation, { loading: isLoadingDuplication }] =
        useDuplicatePackageMutation()
    const customStyles = styles()
    const [open, setOpen] = useState(false)
    const [duplicateId, setDuplicateId] = useState('')
    const [publicName, setPublicName] = useState('')
    const [ajatId, setAjatId] = useState('')
    const notify = useNotify()

    const togglePopup = (): void => {
        setOpen(!open)
    }

    const handleDuplicateClick = (e: MouseEvent<SVGSVGElement>, id: string) => {
        e?.stopPropagation() // Stops rowClick from firing
        setPublicName('')
        setAjatId('')
        setDuplicateId(id)
        togglePopup()
    }

    const handlePublicNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPublicName(e.target.value)
    }

    const handleAjatIdChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAjatId(e.target.value)
    }

    const handlePopupSubmit = () => {
        duplicatePackageMutation({
            variables: { id: duplicateId, public_name: publicName, ajat_id: ajatId }
        })
            .then((result) => {
                redirect('edit', '/Package', result.data?.duplicatePackage?.id)
            })
            .catch((error) => notify(error.message, 'error'))
    }

    return (
        <>
            <List
                {...props}
                sort={{ field: 'public_name', order: 'DESC' }}
                empty={false}
                perPage={DEFAULT_PER_PAGE}
                filters={PackageListFilters}
                bulkActionButtons={<BulkActionButtons isWithActionsFlush />}
                pagination={<Pagination rowsPerPageOptions={ROWS_PER_PAGE} />}
            >
                <Datagrid body={<CustomDatagridBody />} classes={datagridClasses} rowClick="edit">
                    <TextField
                        source="public_name"
                        label={translate('manager.resources.package.public_name')}
                    />
                    <TextField
                        source="type"
                        label={translate('manager.resources.package.type')}
                        sortable={false}
                    />
                    <FunctionField
                        source="active"
                        label={translate('manager.resources.general.status')}
                        render={(record?: Record | undefined) => (
                            <DatagridPublishedColumnContent record={record} />
                        )}
                    />
                    <DateField
                        label={translate('manager.resources.general.last_updated')}
                        source="updated_at"
                        showTime
                    />
                    <FunctionField
                        label={translate('manager.resources.general.actions')}
                        render={(record?: Record | undefined) =>
                            record?.id && (
                                <Tooltip title={translate('manager.resources.general.duplicate')}>
                                    <FileCopyIcon
                                        className={classes.fileIcon}
                                        onClick={(e: MouseEvent<SVGSVGElement>) =>
                                            handleDuplicateClick(e, record.id.toString())
                                        }
                                    />
                                </Tooltip>
                            )
                        }
                    />
                </Datagrid>
            </List>
            <Dialog fullWidth open={open} onClose={togglePopup}>
                <DialogTitle>
                    <Typography variant="h6" gutterBottom>
                        {translate('manager.resources.package.duplicate_package')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.package.public_name')}
                        onChange={handlePublicNameChange}
                    />
                    <MaterialTextfield
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        label={translate('manager.resources.package.package_id')}
                        onChange={handleAjatIdChange}
                    />
                    <DialogActions className={customStyles.dialogActions}>
                        <Button onClick={togglePopup} variant="contained" color="default">
                            {translate('manager.resources.packageGroup.package.close')}
                        </Button>
                        <Button
                            onClick={handlePopupSubmit}
                            variant="contained"
                            color="primary"
                            disabled={!publicName || !ajatId || isLoadingDuplication}
                        >
                            {translate('manager.resources.packageGroup.package.add_button')}
                            {isLoadingDuplication && (
                                <CircularProgress size={24} className={customStyles.spinner} />
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default PackageList

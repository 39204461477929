import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolsSchoolQueryVariables = Types.Exact<{
  schoolId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type SchoolsSchoolQuery = { __typename?: 'Query', schoolsSchool?: { __typename?: 'schoolsSchool', id: string, tags: Array<{ __typename?: 'schoolsSchoolTag', tag: string, id: string }> } | null | undefined };


export const SchoolsSchoolDocument = gql`
    query SchoolsSchool($schoolId: ID) {
  schoolsSchool(id: $schoolId) {
    id
    tags {
      tag
      id
    }
  }
}
    `;

/**
 * __useSchoolsSchoolQuery__
 *
 * To run a query within a React component, call `useSchoolsSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsSchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useSchoolsSchoolQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsSchoolQuery, SchoolsSchoolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsSchoolQuery, SchoolsSchoolQueryVariables>(SchoolsSchoolDocument, options);
      }
export function useSchoolsSchoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsSchoolQuery, SchoolsSchoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsSchoolQuery, SchoolsSchoolQueryVariables>(SchoolsSchoolDocument, options);
        }
export type SchoolsSchoolQueryHookResult = ReturnType<typeof useSchoolsSchoolQuery>;
export type SchoolsSchoolLazyQueryHookResult = ReturnType<typeof useSchoolsSchoolLazyQuery>;
export type SchoolsSchoolQueryResult = Apollo.QueryResult<SchoolsSchoolQuery, SchoolsSchoolQueryVariables>;
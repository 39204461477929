import {
    BooleanInput,
    useTranslate,
    TabbedForm,
    FormTab,
    TextInput,
    required,
    NumberInput,
    regex,
    Record,
    TopToolbar,
    useRedirect
} from 'react-admin'
import { Box, Grid, Typography, Button } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import { makeStyles } from '@material-ui/core/styles'
import { FormSpy } from 'react-final-form'

import TextAreaInput from '../../components/formfields/TextAreaInput'
import CountriesSelect from '../../components/formfields/CountriesSelect'
import DefaultToolbar from '../../components/common/DefaultToolbar'
import SchoolEditToolbar from './SchoolEditToolbar'
import SchoolTagsInput from './SchoolTagsInput'
import SchoolPeriodList from '../schoolPeriod/SchoolPeriodList'
import SchoolClassList from '../schoolClass/SchoolClassList'
import UploadField from 'components/formfields/uploadField/UploadField'
import { formStyles } from 'layout/styles/formStyles'
import SchoolClassRepresentativeListAsArrayField from 'resources/schoolClassRepresentative/SchoolClassRepresentativeListAsArrayField'
import { Variables } from 'components/variables'

const useStyles = makeStyles(formStyles)

const useCustomStyles = makeStyles({
    paddingBottom: {
        paddingBottom: 16
    }
})

const SchoolClassesTab = (props: Record | undefined) => {
    const translate = useTranslate()
    const classes = useCustomStyles()
    const redirect = useRedirect()

    const handleLinkClass = () => {
        redirect('create', '/SchoolClass', props?.record?.id, {}, { schoolId: props?.record?.id })
    }

    return (
        <>
            <TopToolbar>
                <Button color="primary" variant={'outlined'} onClick={handleLinkClass}>
                    <AddIcon />
                    {translate('manager.resources.school.link_class')}
                </Button>
            </TopToolbar>
            {props?.record?.classes?.length ? (
                <SchoolClassList
                    filter={{ school_id: parseInt(props?.record?.id) }}
                    className={classes.paddingBottom}
                />
            ) : (
                <Typography variant="body1" gutterBottom className={classes.paddingBottom}>
                    {translate('manager.resources.school.no_classes')}
                </Typography>
            )}
        </>
    )
}

const SchoolForm = ({ isPristineForm, setIsPristineFormCallback, ...props }) => {
    const translate = useTranslate()
    const formStyle = useStyles()
    const validateRegex = regex(/^[\w-]+$/, translate('manager.regex.az09'))

    return (
        <TabbedForm
            {...props}
            toolbar={props.isEdit ? <SchoolEditToolbar {...props} /> : <DefaultToolbar />}
            redirect={props.basePath}
        >
            <FormTab label={translate('manager.tabs.general')} path="/">
                <Grid container className={formStyle.fullWidth}>
                    <Grid container>
                        <Grid item md={7}>
                            <Typography variant="h6" gutterBottom>
                                {translate('manager.resources.school.school')}
                            </Typography>
                        </Grid>
                        <Grid item md={1} />
                        <Grid item md={4}>
                            <Typography variant="h6" gutterBottom>
                                {translate('manager.resources.school.representative')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={7}>
                        <Grid item md={12}>
                            <TextInput
                                fullWidth
                                source="ajat_id"
                                variant="outlined"
                                label={translate('manager.resources.school.ajat_id')}
                                validate={[required(), validateRegex]}
                                helperText={translate('manager.regex.az09')}
                                disabled={props.isEdit}
                            />
                            <TextInput
                                fullWidth
                                source="name"
                                variant="outlined"
                                label={translate('manager.resources.school.name')}
                                validate={requiredValidate}
                            />
                            <TextInput
                                source="address"
                                variant="outlined"
                                label={translate('manager.resources.school.address')}
                                fullWidth
                                validate={requiredValidate}
                            />
                            <TextInput
                                source="post_code"
                                variant="outlined"
                                label={translate('manager.resources.school.post_code')}
                                fullWidth
                                validate={requiredValidate}
                            />
                            <TextInput
                                source="city"
                                variant="outlined"
                                label={translate('manager.resources.school.city')}
                                fullWidth
                                validate={requiredValidate}
                            />
                            <CountriesSelect fullWidth validate={requiredValidate} />
                            <TextInput
                                source="embroidery_1"
                                variant="outlined"
                                label={translate('manager.resources.school.embroidery_1')}
                                fullWidth
                            />
                            <TextInput
                                source="embroidery_2"
                                variant="outlined"
                                label={translate('manager.resources.school.embroidery_2')}
                                fullWidth
                            />
                            <TextInput
                                source="embroidery_3"
                                variant="outlined"
                                label={translate('manager.resources.school.embroidery_3')}
                                fullWidth
                            />
                            <TextInput
                                source="marketing_url"
                                variant="outlined"
                                label={translate('manager.resources.school.marketing_url')}
                                fullWidth
                                helperText={translate('manager.regex.az09')}
                                validate={validateRegex}
                            />
                            <NumberInput
                                source="students_amount"
                                variant="outlined"
                                label={translate('manager.resources.school.students_amount')}
                                fullWidth
                            />
                            <NumberInput
                                source="priority"
                                variant="outlined"
                                label={translate('manager.resources.school.priority')}
                                fullWidth
                            />
                            <Box mb={2} mt={2}>
                                <TextAreaInput
                                    fullWidth
                                    source="internal_note"
                                    name="internal_note"
                                    label={translate('manager.resources.school.internal_note')}
                                    rows={3}
                                    multiline={true}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={4}>
                        <Grid item md={12} className={formStyle.flexStart}>
                            <TextInput
                                source="representative_name"
                                variant="outlined"
                                label={translate('manager.resources.school.representative_name')}
                                fullWidth
                            />
                            <TextInput
                                source="representative_email"
                                variant="outlined"
                                label={translate('manager.resources.school.representative_email')}
                                fullWidth
                            />
                            <TextInput
                                source="representative_telephone"
                                variant="outlined"
                                label={translate(
                                    'manager.resources.school.representative_telephone'
                                )}
                                fullWidth
                            />
                            {props?.record?.id && (
                                <>
                                    <Typography variant="h6" gutterBottom>
                                        {translate('manager.resources.school.tags')}
                                    </Typography>
                                    <SchoolTagsInput schoolId={props.record.id} />
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <Box mb={2}>
                                <BooleanInput
                                    row
                                    source="active"
                                    label={translate('manager.resources.general.published')}
                                    defaultValue={true}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </FormTab>
            {props?.record?.id && (
                <FormTab label={translate('manager.tabs.periods')} path="periods">
                    <SchoolPeriodList filter={{ school_id: parseInt(props?.record?.id) }} />
                </FormTab>
            )}
            {!!props.isEdit && (
                <FormTab label={translate('manager.tabs.classes')} path="classes">
                    <SchoolClassesTab props={props} id={props?.id} />
                </FormTab>
            )}
            <FormTab label={translate('manager.tabs.images')} path="images">
                <Grid container className={formStyle.fullWidth}>
                    <Grid item md={12}>
                        <Typography variant="h6" gutterBottom>
                            {translate('manager.tabs.images')}
                        </Typography>
                        <UploadField
                            data={props?.record?.logo}
                            url={props?.record?.logo}
                            source="logo"
                            label={translate('manager.resources.school.logo')}
                        />
                        <UploadField
                            data={props?.record?.logo_2}
                            url={props?.record?.logo_2}
                            source="logo_2"
                            label={`${translate('manager.resources.school.logo')} 2`}
                        />
                        <UploadField
                            data={props?.record?.logo_3}
                            url={props?.record?.logo_3}
                            source="logo_3"
                            label={`${translate('manager.resources.school.logo')} 3`}
                        />
                        <UploadField
                            data={props?.record?.logo_4}
                            url={props?.record?.logo_4}
                            source="logo_4"
                            label={`${translate('manager.resources.school.logo')} 4`}
                        />
                        <UploadField
                            data={props?.record?.personalization_logo}
                            url={props?.record?.personalization_logo}
                            source="personalization_logo"
                            label={translate('manager.resources.school.personalization_logo')}
                        />
                        <UploadField
                            data={props?.record?.hero_banner}
                            url={props?.record?.hero_banner}
                            source="hero_banner"
                            label={translate('manager.resources.school.hero_banner')}
                        />
                    </Grid>
                </Grid>
            </FormTab>
            {!!props.isEdit && (
                <FormTab label={translate('manager.tabs.contact_persons')} path="contact-persons">
                    <Grid container className={formStyle.fullWidth}>
                        <SchoolClassRepresentativeListAsArrayField {...props} />
                    </Grid>
                </FormTab>
            )}
            <FormTab label={translate('manager.tabs.variables')} path="variables">
                <Variables record={props?.record} entityType="school" />
            </FormTab>
            <FormSpy
                subscription={{ pristine: true }}
                onChange={(props) => {
                    setIsPristineFormCallback(props?.pristine)
                }}
            />
        </TabbedForm>
    )
}

const requiredValidate = [required()]

export default SchoolForm

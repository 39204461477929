import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useRootNodesQuery } from 'apollo/configurator/queries/RootNodes.generated'
import { useContext, useEffect, useState } from 'react'
import { ModelContext } from 'context/model/ModelContext'
import AttributesEditor from '../molecules/manager/AttributesEditor'
import ModelSelector from '../molecules/manager/ModelSelector'
import TreeView from '../molecules/manager/TreeView'
import { Loading } from 'react-admin'

const useStyles = makeStyles(() => ({
    divider: {
        borderLeft: '1px solid #eaeaea',
        height: 'calc(100vh - 75px)',
        padding: ' 0 25px'
    }
}))

const Manager = () => {
    const classes = useStyles()
    const [nodeId, setNodeId] = useState<number | null>()
    const [modelId, setModelId] = useState<number | null>()
    const { data: { configuratorNodes: nodes } = {} } = useRootNodesQuery()
    const [parentId, setParentId] = useState<string>()
    const { state } = useContext(ModelContext)

    const handleNodeClick = (id: number | null, parentId: string) => {
        setNodeId(id)
        setParentId(parentId)
    }

    const handleModelSelection = (model: number | null) => {
        setModelId(model)
    }

    useEffect(() => {
        if (+state.currentModel !== modelId) {
            setModelId(+state.currentModel)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentModel]) // Disabled because it breaks rendering of the TreeView

    const rootnode = nodes?.find((node: any) => node.id === modelId || +node.id === modelId) as any

    // Loading
    if (!nodes?.length) return <Loading />

    return (
        <ModelContext.Consumer>
            {() => (
                <Grid container>
                    <Grid item xs={12}>
                        {nodes && (
                            <ModelSelector setModel={handleModelSelection} nodes={nodes as any} />
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {modelId ? (
                            <>
                                <TreeView handleNodeClick={handleNodeClick} rootNode={rootnode} />
                            </>
                        ) : null}
                    </Grid>
                    <Grid item xs={6}>
                        {nodeId ? (
                            <div className={classes.divider}>
                                <AttributesEditor id={+nodeId} parentId={parentId} />
                            </div>
                        ) : null}
                    </Grid>
                </Grid>
            )}
        </ModelContext.Consumer>
    )
}

export default Manager
